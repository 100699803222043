import { formatDate2 } from './date'
import {
  getDimensionTranslationFromTitle,
  getSourceValueDimensionTranslation
} from './explore'
import { hasEditorPermissionBySource } from './source'
import { useCubeStore } from '~/stores/cube'
import type { Dimension, Measure } from '~/types/cube'
import {
  SourceValueDimension,
  type NormalizedFieldsByDimension,
  type SourceValueDimensionOption,
  type SourceValueWithDimensionTitle,
  type SourceValueWithMissingNormalizedFields,
  type SourceValueWithNormalizedFields,
  type SourceValuesByDimension,
  type SourceValue,
  NormalizedFieldEntry,
  type NormalizationAnalyticsByFieldEntry
} from '~/types/sourceValue'

export const getSortedSourceValuesByDimension = <
  T extends SourceValueWithDimensionTitle
>(
  sourceValues: T[]
) => {
  const unsortedSourceValuesByDimension = sourceValues.reduce<{
    [key: string]: T[]
  }>(
    (
      sourceValuesByDimension: {
        [key: string]: T[]
      },
      sourceValueWithDimensionTitle: T
    ) => ({
      ...sourceValuesByDimension,
      [sourceValueWithDimensionTitle.dimension]: [
        ...(sourceValuesByDimension[sourceValueWithDimensionTitle.dimension] ??
          []),
        sourceValueWithDimensionTitle
      ]
    }),
    {}
  )

  return Object.entries(unsortedSourceValuesByDimension).reduce<{
    [key: string]: T[]
  }>(
    (
      sourceValuesByDimension: { [key: string]: T[] },
      [dimensionTitle, sourceValues]: [string, T[]]
    ) => ({
      ...sourceValuesByDimension,
      [dimensionTitle]: sortSourceValuesByLastUsedAt(sourceValues)
    }),
    {}
  )
}

export const getSourceValueDimensionOptions = (
  sourceValuesByDimension: SourceValuesByDimension,
  isNormalization: boolean = false
): SourceValueDimensionOption[] =>
  Object.values(SourceValueDimension)
    .filter(dimensionKey => sourceValuesByDimension[dimensionKey])
    .map(dimensionKey => {
      const dimensionTitle =
        sourceValuesByDimension[dimensionKey]![0]!.dimensionTitle

      return {
        label: dimensionTitle
          ? getDimensionTranslationFromTitle(dimensionTitle)
          : getSourceValueDimensionTranslation(dimensionKey, isNormalization),
        dimensionKey
      }
    })

const sortSourceValuesByLastUsedAt = <T extends SourceValueWithDimensionTitle>(
  sourceValues: T[]
) =>
  sourceValues.toSorted((sourceValue1, sourceValue2) => {
    if (!sourceValue1.lastUsedAt && !sourceValue2.lastUsedAt) return 0
    if (!sourceValue1.lastUsedAt) return -1
    if (!sourceValue2.lastUsedAt) return 1

    return (
      new Date(sourceValue2.lastUsedAt).getTime() -
      new Date(sourceValue1.lastUsedAt).getTime()
    )
  })

export const buildSourceValuesWithMissingNormalizedFields = (
  normalizedFieldsByDimension: NormalizedFieldsByDimension,
  sourceValues: SourceValueWithNormalizedFields[]
): SourceValueWithMissingNormalizedFields[] => {
  return sourceValues.map(sourceValue => ({
    ...sourceValue,
    normalizedFields: normalizedFieldsByDimension[sourceValue.dimension]!.map(
      normalizedFieldEntry => {
        const normalizedField = sourceValue.normalizedFields.find(
          normalizedField => normalizedField.field === normalizedFieldEntry
        )

        return normalizedField
          ? {
              id: normalizedField.id,
              field: normalizedField.field,
              normalizedValue: normalizedField.normalizedValue
            }
          : { normalizedValue: null, field: normalizedFieldEntry }
      }
    )
  }))
}

export const getSourceValueFullTitle = (sourceValue: SourceValue) => {
  const { $t } = useNuxtApp()
  return `${sourceValue.sourceValue}\n${$t(
    'sharedAnalytics.sourceValue.lastUsedAt',
    {
      date: sourceValue.lastUsedAt ? formatDate2(sourceValue.lastUsedAt) : '-'
    }
  )}`
}

export const hasEditorPermissionBySourceValue = (sourceValue: SourceValue) => {
  return hasEditorPermissionBySource(sourceValue.source.id)
}

export const filterEditableSourceValue = <T extends SourceValue>(
  sourceValues: T[],
  selectedSourceId: string | null,
  isEditable: boolean
) => {
  const editableSourceValues = sourceValues.filter(
    sourceValue => !isEditable || hasEditorPermissionBySourceValue(sourceValue)
  )

  return editableSourceValues.filter(
    sourceValue =>
      !selectedSourceId || sourceValue.source.id === selectedSourceId
  )
}

export const getNormalizationFieldAnalytics = (
  sourceValuesByDimension:
    | {
        [key: string]: SourceValueWithNormalizedFields[]
      }
    | undefined,
  normalizedFieldsByDimension: NormalizedFieldsByDimension | undefined
): NormalizationAnalyticsByFieldEntry | undefined => {
  if (!normalizedFieldsByDimension || !sourceValuesByDimension) return undefined

  return Object.values(NormalizedFieldEntry).reduce(
    (acc, normalizedFieldEntry) => {
      const associatedDimension = Object.keys(normalizedFieldsByDimension).find(
        dimension =>
          normalizedFieldsByDimension[dimension]?.includes(normalizedFieldEntry)
      )!

      const valuesTotal =
        sourceValuesByDimension[associatedDimension]?.length || 0

      const normalizedValuesTotal =
        sourceValuesByDimension[associatedDimension]?.filter(value =>
          value.normalizedFields.some(
            field => field.field === normalizedFieldEntry
          )
        ).length || 0

      acc[normalizedFieldEntry] = {
        normalizedValuesTotal,
        valuesTotal
      }
      return acc
    },
    {} as NormalizationAnalyticsByFieldEntry
  )
}

export const getImpactedMembers = (
  normalizedFieldEntry: NormalizedFieldEntry
): { measures: Measure[]; dimensions: Dimension[] } => {
  const { measuresMeta, dimensionsMeta } = useCubeStore()

  return {
    measures: measuresMeta
      ? measuresMeta.filter(
          m =>
            m.isVisible &&
            m.meta?.normalizationFieldEntries &&
            m.meta.normalizationFieldEntries.includes(normalizedFieldEntry)
        )
      : [],
    dimensions: dimensionsMeta
      ? dimensionsMeta.filter(
          d =>
            d.isVisible &&
            d.meta?.normalizationFieldEntries &&
            d.meta.normalizationFieldEntries.includes(normalizedFieldEntry)
        )
      : []
  }
}
